import pcdData from "./pcd.json";

interface PCDEntry {
  code: string;
  name: string;
  children?: Array<PCDEntry>;
}

interface MapEntry {
  code: string;
  name: string;
  children?: Array<string>;
}

let pcd = {
  map: {} as Record<string, MapEntry>, // A flat ID to Object Mapping
  provinces: pcdData.map((p) => ({ code: p.code, name: p.name })),
};

// Converts pcd array to map
function rec(map: Record<string, MapEntry>, items: Array<PCDEntry>) {
  items.forEach((item) => {
    let children = item.children;
    map[item.code] = {
      ...item,
      children: children ? children.map((child) => child.code) : undefined,
    };
    if (children) rec(map, children);
  });
}
rec(pcd.map, pcdData);

export const PCD = pcd;

const DEGREE = [
  "初中及以下",
  "中专",
  "高中",
  "大专",
  "本科",
  "硕士",
  "博士",
  "博士以上",
];

const WORK_EXP_REQUIREMENT_YEARS = [
  ...Array(11)
    .fill(0)
    .map((el, index) => index + 1),
  15,
];
let WORK_EXP_REQUIREMENT: Record<string, number> = {
  不限: 0,
  应届生: -1,
};
WORK_EXP_REQUIREMENT_YEARS.forEach((year) => {
  WORK_EXP_REQUIREMENT[`${year}年以上`] = year;
});

export const REGIONS = [
  { code: "CN", name: "中国大陆" },
  { code: "US", name: "美国" },
  { code: "UK", name: "英国" },
  { code: "HKMOTW", name: "港澳台地区" },
  { code: "EU", name: "欧盟" },
  { code: "AU", name: "澳大利亚" },
  { code: "SASIA", name: "东南亚地区" },
  { code: "CA", name: "加拿大" },
  { code: "NZ", name: "新西兰" },
  { code: "JP", name: "日本" },
  { code: "KR", name: "韩国" },
  { code: "MASIA", name: "中东地区" },
];

export let REGION_MAP: Record<string, Record<string, string>> = {};
REGIONS.forEach((r) => (REGION_MAP[r.code] = r));

export const GENDER_OPTIONS = ["未知", "男", "女"];

export const DEGREE_OPTIONS = DEGREE;

export const LANGUAGE_LEVEL_OPTIONS = [
  "简单邮件沟通口语不能沟通",
  "邮件熟练沟通口语简单沟通",
  "邮件口语均熟练沟通",
  "母语水平",
];

export const SOFTWARE_TYPE_OPTIONS = [
  // TODO: split this into two columns
  "其他",
  "AutoCAD 2D",
  "Microstation 2D",
  "Vectorworks",
  "Revit",
  "ArchiCAD",
  "Bentley Architecture",
  "Photoshop",
  "Illustrator",
  "InDesign",
  "Rhino",
  "3D Studio Max",
  "SketchUp",
  "手绘草图",
  "实物模型制作",
];

export const SOFTWARE_LEVEL_OPTIONS = ["一星", "两星", "三星", "四星", "五星"];

export const COMPANY_INDUSTRY_OPTIONS = [
  "房地产开发",
  "施工工程",
  "建筑设计",
  "规划设计",
  "室内设计",
  "景观设计",
  "房地产服务",
  "物业管理",
  "地产经纪",
  "人才招聘",
];

export const COMPANY_SIZE_OPTIONS = [
  "1-50",
  "51-100",
  "101-500",
  "501-1000",
  "1001-5000",
  "5001-10000",
  "10000人以上",
];

export const COMPANY_TYPE_OPTIONS = ["国有企业", "民营企业", "外资企业"];

export const JOB_TYPE_OPTIONS = ["全职", "兼职", "实习"];

export const JOB_CATEGORY_OPTIONS = [
  "其他",
  "建筑设计",
  "室内设计",
  "景观设计",
  "规划设计",
  "结构设计",
  "项目管理",
  "商务市场",
  "地产开发商",
  "施工工程",
  "人力资源及行政",
  "平面设计",
  "房地产咨询",
  "IT类",
];

export const JOB_DEGREE_REQUIREMENT_OPTIONS = ["不限", ...DEGREE];

export const JOB_WORK_EXP_OPTIONS = WORK_EXP_REQUIREMENT;

export const JOB_LANGUAGE_TYPE_OPTIONS = [
  "不限",
  "其他",
  "英语",
  "日语",
  "法语",
  "普通话",
  "粤语",
];

export const JOB_SALARY_TYPE_OPTIONS = ["面谈", "输入月薪范围", "输入年薪范围"];

export const SALARY_TYPE = ["税前", "税后"];

export const JOB_CONTACT_DISPLAY = ["显示", "隐藏"];

export const OCCUPATION_OPTIONS = [
  "其他",
  "建筑设计师",
  "室内设计师",
  "软装设计师",
  "规划设计师",
  "景观设计师",
  "机电给排水暖通设计师",
  "结构设计师",
  "平面视觉设计师",
  "商务市场营销",
  "运营行政人事",
];

export const TagLogic = {
  Or: 1,
  And: 2,
};
