import React from "react";
import {
  GENDER_OPTIONS,
  OCCUPATION_OPTIONS,
  SALARY_TYPE,
  DEGREE_OPTIONS,
  LANGUAGE_LEVEL_OPTIONS,
  SOFTWARE_LEVEL_OPTIONS,
  SOFTWARE_TYPE_OPTIONS,
} from "constant";
import { formatDate, formatLocation, formatTime } from "utils/format";
import NavBar from "components/NavBar";
import styles from "./index.module.scss";

const defaultProjectTypeExp: ProjectTypeExp = {
  "architectural-design": [],
  "planning-and-design": [],
  "interior-design": [],
  "landscape-design": [],
  "design-expertise": [],
};

const projectTypeMap: Record<
  ProjectType,
  { title: string; options: Array<string> }
> = {
  "architectural-design": {
    title: "建筑设计",
    options: [
      "学校",
      "商业综合体",
      "商业街",
      "酒店娱乐",
      "医院",
      "剧场剧院",
      "会展会议中心",
      "文化艺术中心",
      "住宅",
      "博物馆",
      "超高层",
      "民宿",
      "办公",
      "零售",
      "交通TOD",
      "改造旧改",
      "体育场馆",
      "养老",
      "市政",
      "售楼处",
      "机场",
      "地下空间",
      "历史保护建筑",
      "bim",
    ],
  },
  "planning-and-design": {
    title: "规划设计",
    options: ["总规控规", "城市设计", "前期咨询"],
  },
  "interior-design": {
    title: "室内设计",
    options: [
      "样板房",
      "售楼处会所",
      "餐饮",
      "办公",
      "零售旗舰店",
      "改造",
      "酒店",
      "商业商场",
      "医疗医院",
      "文化馆/展览馆/博物馆",
      "别墅",
      "公区",
      "展览展示",
      "体育馆",
      "养老",
      "机场",
      "地铁火车站",
    ],
  },
  "landscape-design": {
    title: "景观设计",
    options: ["滨水公园", "市政景观", "住宅景观", "游乐园"],
  },
  "design-expertise": {
    title: "您擅长方案，扩初还是施工图设计",
    options: ["方案设计", "扩初设计", "施工图设计"],
  },
};

interface Props {
  user: UserView;
}

const User = ({ user }: Props) => {
  const projectTypeExp: ProjectTypeExp = {
    ...defaultProjectTypeExp,
    ...user.projectTypeExp,
  };

  return (
    <div className={styles["user-page"]}>
      <NavBar />
      <div className={styles["page-content"]}>
        <section>
          <div className={styles["section-title"]}>基本信息</div>
          <div className={styles.field}>
            <div className={styles.title}>姓名</div>
            <div className={styles.content}>{user.name}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.title}>性别</div>
            <div className={styles.content}>{GENDER_OPTIONS[user.gender]}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.title}>出生日期</div>
            <div className={styles.content}>{formatDate(user.birthday)}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.title}>职业</div>
            {user.occupation ? (
              <div className={styles.content}>
                {user.occupation.type === 0
                  ? user.occupation.name
                  : OCCUPATION_OPTIONS[user.occupation.type]}
              </div>
            ) : (
              <div className={styles["no-content"]}>-</div>
            )}
          </div>
          <div className={styles.field}>
            <div className={styles.title}>手机</div>
            <div className={styles.content}>{user.phone}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.title}>邮箱</div>
            <div className={styles.content}>{user.email}</div>
          </div>
        </section>

        <section>
          <div className={styles["section-title"]}>求职信息</div>
          <div className={styles.field}>
            <div className={styles.title}>到岗需时</div>
            {typeof user.timeToOnboard !== "undefined" ? (
              <div className={styles.content}>{user.timeToOnboard}月</div>
            ) : (
              <div className={styles["no-content"]}>-</div>
            )}
          </div>
          <div className={styles.field}>
            <div className={styles.title}>期望工作地点</div>
            {user.expectedLocation.region ? (
              <div className={styles.content}>
                {formatLocation(user.expectedLocation)}
              </div>
            ) : (
              <div className={styles["no-content"]}>-</div>
            )}
          </div>
          <div className={styles.field}>
            <div className={styles.title}>目前年薪</div>

            {user.currentSalary ? (
              <div className={styles.content}>
                {SALARY_TYPE[user.currentSalaryType]} {user.currentSalary}
              </div>
            ) : (
              <div className={styles["no-content"]}>-</div>
            )}
          </div>
          <div className={styles.field}>
            <div className={styles.title}>期望年薪</div>
            {user.expectedSalary ? (
              <div className={styles.content}>
                {SALARY_TYPE[user.expectedSalaryType]} {user.expectedSalary}
              </div>
            ) : (
              <div className={styles["no-content"]}>-</div>
            )}
          </div>
          <div className={styles.field}>
            <div className={styles.title}>求职意向</div>
            {user.careerObjective ? (
              <div className={styles.content}>{user.careerObjective}</div>
            ) : (
              <div className={styles["no-content"]}>-</div>
            )}
          </div>
        </section>

        <section>
          <div className={styles["section-title"]}>教育背景</div>
          {user.educationExps.map((exp, index) => (
            <div key={index}>
              <div className={styles.field}>
                <div className={styles.title}>学校名称</div>
                <div className={styles.content}>{exp.school}</div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>专业名称</div>
                <div className={styles.content}>{exp.major}</div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>学历</div>
                <div className={styles.content}>
                  {DEGREE_OPTIONS[exp.degree]}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>开始时间</div>
                <div className={styles.content}>
                  {formatTime(exp.startTime)}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>结束时间</div>
                <div className={styles.content}>{formatTime(exp.endTime)}</div>
              </div>
            </div>
          ))}
        </section>

        <section>
          <div className={styles["section-title"]}>工作经历</div>
          {user.workExps.map((exp, index) => (
            <div key={index}>
              <div className={styles.field}>
                <div className={styles.title}>公司名称</div>
                <div className={styles.content}>{exp.company}</div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>职位名称</div>
                <div className={styles.content}>{exp.position}</div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>部门名称</div>
                <div className={styles.content}>{exp.department}</div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>开始时间</div>
                <div className={styles.content}>
                  {formatTime(exp.startTime)}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>结束时间</div>
                <div className={styles.content}>{formatTime(exp.endTime)}</div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>描述</div>
                {exp.description ? (
                  <div className={styles.content}>{exp.description}</div>
                ) : (
                  <div className={styles["no-content"]}>-</div>
                )}
              </div>
            </div>
          ))}
        </section>

        <section>
          <div className={styles["section-title"]}>项目经验</div>
          {user.projectExps.length ? (
            <div className={styles["section-content"]}>
              {user.projectExps.map((exp, index) => (
                <div className={styles["section-content-item"]} key={index}>
                  <div className={styles.field}>
                    <div className={styles.title}>项目名称</div>
                    <div className={styles.content}>{exp.project}</div>
                  </div>
                  <div className={styles.field}>
                    <div className={styles.title}>公司名称</div>
                    <div className={styles.content}>{exp.company}</div>
                  </div>
                  <div className={styles.field}>
                    <div className={styles.title}>职责</div>
                    <div className={styles.content}>{exp.duty}</div>
                  </div>
                  <div className={styles.field}>
                    <div className={styles.title}>贡献</div>
                    <div className={styles.content}>{exp.contribution}</div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles["section-no-content"]}>无</div>
          )}
        </section>

        <section>
          <div className={styles["section-title"]}>语言能力</div>
          {user.languageSkills.length ? (
            <div className={styles["section-content"]}>
              {user.languageSkills.map((skill, index) => (
                <div className={styles["section-content-item"]} key={index}>
                  <div className={styles.field}>
                    <div className={styles.title}>语言</div>
                    <div className={styles.content}>{skill.name}</div>
                  </div>
                  <div className={styles.field}>
                    <div className={styles.title}>熟练程度</div>
                    <div className={styles.content}>
                      {LANGUAGE_LEVEL_OPTIONS[skill.level]}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles["section-no-content"]}>无</div>
          )}
        </section>

        <section>
          <div className={styles["section-title"]}>软件能力</div>
          {user.softwareSkills.length ? (
            <div className={styles["section-content"]}>
              {user.softwareSkills.map((skill, index) => (
                <div className={styles["section-content-item"]} key={index}>
                  <div className={styles.field}>
                    <div className={styles.title}>软件</div>
                    <div className={styles.content}>
                      {skill.type === 0
                        ? skill.name
                        : SOFTWARE_TYPE_OPTIONS[skill.type]}
                    </div>
                  </div>
                  <div className={styles.field}>
                    <div className={styles.title}>熟练程度</div>
                    <div className={styles.content}>
                      {SOFTWARE_LEVEL_OPTIONS[skill.level]}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles["section-no-content"]}>无</div>
          )}
        </section>

        <section>
          <div className={styles["section-title"]}>参与项目类型</div>
          <div className={styles["section-body"]}>
            {(Object.keys(defaultProjectTypeExp) as Array<ProjectType>).map(
              (projectType) => {
                const arr = projectTypeExp[projectType];

                return (
                  <section key={projectType}>
                    <div className={styles["section-title"]}>
                      {projectTypeMap[projectType].title}
                    </div>
                    {arr.length ? (
                      <div className={styles["section-content"]}>
                        <div className={styles["section-content-item"]}>
                          {arr.map((exp, index) => (
                            <div className={styles.field} key={index}>
                              <div className={styles.title}>{exp}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className={styles["section-no-content"]}>无</div>
                    )}
                  </section>
                );
              }
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default User;
