import React from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";

const Home = () => {
  return (
    <div className={styles["home-page"]}>
      <div className={styles["page-content"]}>
        <h1>Archibucks招聘管理系统</h1>
        <Link to="/user-list" className={styles["primary-link"]}>
          查看所有申请人
        </Link>
        <Link to="/search-user" className={styles["primary-link"]}>
          查找申请人
        </Link>
        <Link to="/edit-tag" className={styles["primary-link"]}>
          编辑标签
        </Link>
        <Link to="/company-list" className={styles["primary-link"]}>
          编辑公司/职位信息
        </Link>
        <Link to="/job-priority" className={styles["primary-link"]}>
          编辑职位显示优先级
        </Link>
      </div>
    </div>
  );
};

export default Home;
